import React from "react";

export interface LogoProps {
  variant?: "default" | "outline";
}

export const Logo = ({ variant }: LogoProps) => {
  return (
    <svg
      className="w-full h-auto"
      viewBox="0 0 1018 1044"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1018"
        height="1044"
      >
        <ellipse cx="509" cy="521.941" rx="509" ry="521.941" fill="#292A44" />
      </mask>
      <g mask="url(#mask0)">
        {variant === "outline" ? (
          <ellipse cx="509" cy="521.941" rx="509" ry="521.941" fill="#F92672" />
        ) : null}
        <ellipse
          cx="509.125"
          cy="521.941"
          rx="465.63"
          ry="478.446"
          fill="#292A44"
        />
        <path
          d="M250.751 521.206C260.457 507.393 271.656 496.754 284.349 489.288C297.041 481.822 310.293 478.089 324.106 478.089C347.25 478.089 365.169 486.302 377.861 502.727C390.554 518.779 396.9 542.297 396.9 573.281V661.754C396.9 695.725 388.874 721.483 372.822 739.028C356.77 756.2 331.945 764.786 298.348 764.786C265.497 764.786 241.419 756.387 226.113 739.588C210.808 722.79 203.155 697.218 203.155 662.874V375.617H250.751V521.206ZM312.906 520.646C302.081 520.646 291.068 524.752 279.869 532.965C268.67 541.177 258.964 552.376 250.751 566.562V668.474C250.751 686.766 254.858 700.765 263.07 710.471C271.656 720.177 283.415 725.029 298.348 725.029C332.318 725.029 349.304 705.804 349.304 667.354V580.001C349.304 540.431 337.171 520.646 312.906 520.646ZM517.124 375.617V604.639L606.717 484.808H659.913L566.96 604.639L666.632 758.067H609.517L517.124 611.358V758.067H469.528V375.617H517.124Z"
          fill="#F92672"
        />
        <rect
          x="203.152"
          y="-142.229"
          width="47.5963"
          height="521.941"
          fill="#F92672"
        />
        <rect
          x="602.792"
          y="747.826"
          width="47.5963"
          height="521.941"
          transform="rotate(-32.9803 602.792 747.826)"
          fill="#F92672"
        />
      </g>
    </svg>
  );
};
